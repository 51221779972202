import React, {useEffect, useMemo, useState} from "react";
import { useTable } from 'react-table';

import '../../../../css/Provider/OpenBidPage.css'
import QuotesService from "../../../../services/QuotesService";
import {useModalVisible} from "../../Util/ModalUtil";
import {fillDomainData, renderQuotesRequestModal} from "../../Util/ProviderTableViewUtil";
import {QUOTES_CATEGORY_NAME_MAP} from "../../Constants";

// request, category, city, state, YIB, annual rev, emp, time
const openBidTableColumns = [
    {
        Header: 'Request ID',
        accessor: 'request_id',
    },
    {
        Header: 'Request',
        accessor: 'request_display_name',
    },
    {
        Header: 'Industry',
        accessor: 'industry',
    },
    {
        Header: 'Location',
        accessor: 'location'
    },
    {
        Header: 'YIB',
        accessor: 'year_in_business',
    },
    {
        Header: 'Annual Revenue',
        accessor: 'annual_revenue',
    },
    {
        Header: 'Emp',
        accessor: 'employer_count',
    },
    {
        Header: 'Time',
        accessor: 'time_to_expire',
        toggleHideColumn: false,
    },
    {
        Header: '',
        accessor: 'action_btn',
        Cell: ({
            row: {index},
            handleClickViewBtn,
            tabIdx
        }) => {

        return tabIdx !== 2 && (
            <button
                className='provider-table-view-btn' 
                onClick={() => { handleClickViewBtn(index); }}
            >
              View
            </button>
          )
        }
    }
];

const OpenBidPage = () => {

    const [openBids, setOpenBids] = useState([]);
    const [pendingBids, setPendingBids] = useState([]);
    const [hiddenBids, setHiddenBids] = useState([]);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [tabIdx, setTabIdx] = useState(0);
    const [viewBtnRowIdx, setViewBtnRowIdx] = useState(-1);
    const [request, setRequest] = useState(false);

    // call api to get bid information
    const refresh = (landingTabIdx) => {
        QuotesService.getQuotesRequests()
            .then((res) => {

                // state to control waiting on fullfilled request
                setRequest(true);

                // open bid array
                let openBids = [];

                // extract open bid arrays
                res?.data?.openPaymentProcessingQuotesRequest ? openBids = openBids.concat(res?.data?.openPaymentProcessingQuotesRequest) : openBids.concat([]);
                res?.data?.openPayrollQuotesRequest ? openBids = openBids.concat(res?.data?.openPayrollQuotesRequest) : openBids.concat([]);
                res?.data?.openCreditLineQuotesRequest ? openBids = openBids.concat(res?.data?.openCreditLineQuotesRequest) : openBids.concat([]);
                res?.data?.openLoanQuotesRequest ? openBids = openBids.concat(res?.data?.openLoanQuotesRequest) : openBids.concat([]);
                res?.data?.openInsuranceQuotesRequest ? openBids = openBids.concat(res?.data?.openInsuranceQuotesRequest) : openBids.concat([]);

                setOpenBids(openBids);

                // pending bid array
                let pendingBids = [];

                // extract pending bid arrays
                res?.data?.pendingPaymentProcessingQuotes ? pendingBids = pendingBids.concat(res?.data?.pendingPaymentProcessingQuotes) : pendingBids.concat([]);
                res?.data?.pendingPayrollQuotes ? pendingBids = pendingBids.concat(res?.data?.pendingPayrollQuotes) : pendingBids.concat([]);
                res?.data?.pendingCreditLineQuotes ? pendingBids = pendingBids.concat(res?.data?.pendingCreditLineQuotes) : pendingBids.concat([]);
                res?.data?.pendingLoanQuotes ? pendingBids = pendingBids.concat(res?.data?.pendingLoanQuotes) : pendingBids.concat([]);
                res?.data?.pendingInsuranceQuotes ? pendingBids = pendingBids.concat(res?.data?.pendingInsuranceQuotes) : pendingBids.concat([]);

                setPendingBids(pendingBids);
                setHiddenBids(res?.data?.hiddenQuotesRequests);

                // control tab identifier for open/pending bids
                if (landingTabIdx) {
                    setTabIdx(landingTabIdx);
                }
            }).catch(e => console.log(e));
    };

    // on refresh get bid info from api
    useEffect(refresh, [])

    // when bids are stored run setTableData
    useEffect(() => {
      setTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openBids, tabIdx])

    // sets table data to setData
    const setTableData = () => {
      let bidsDataArr;
      if (tabIdx === 0) {
        bidsDataArr = openBids;
      } else if (tabIdx === 1) {
        bidsDataArr = pendingBids;
      } else {
        bidsDataArr = hiddenBids;
      }

      let tableData;

      if(tabIdx === 0){
        toggleHideColumn("time_to_expire", false);
        tableData = bidsDataArr.map(quotesRequest => {       
          return {
            'request_id': quotesRequest.id,
            'request': QUOTES_CATEGORY_NAME_MAP[quotesRequest.metaData.quoteCategory],
            'request_display_name': quotesRequest?.metaData?.quoteCategory === 'INSURANCE' ? quotesRequest?.type + ' Insurance': QUOTES_CATEGORY_NAME_MAP[quotesRequest.metaData.quoteCategory],
            'requestDateTime': new Date(quotesRequest.metaData.requestTime + 'Z'),
            'industry':quotesRequest.metaData.company?.industry,
            'address': quotesRequest.metaData.company?.address,
            'location': quotesRequest.metaData.company?.city + ', ' + quotesRequest.metaData.company?.state,
            'year_in_business': new Date().getFullYear() - parseInt(quotesRequest.metaData.company?.establishedYear),
            'annual_revenue': quotesRequest.metaData.company?.annualRevenue,
            'employer_count': quotesRequest.metaData.company?.employeeCounts,
            'time_to_expire': calcTimeToExpire(quotesRequest.metaData.expirationTime),
            'company_info': quotesRequest.metaData.company,
            'download_link': quotesRequest.metaData.redactedDocDownloadLink,
            'domain_data': fillDomainData(quotesRequest, true)
          }
        })

        tableData.sort((a, b) => b.requestDateTime - a.requestDateTime);
        
      } else if (tabIdx === 1){
          tableData = bidsDataArr.map(quote => {
              toggleHideColumn("time_to_expire", true);
              return {
                    'request_id': quote?.quotesRequest?.id,
                    'request': QUOTES_CATEGORY_NAME_MAP[quote.quotesRequest.metaData.quoteCategory],
                    'request_display_name': quote?.quotesRequest?.metaData?.quoteCategory === 'INSURANCE' ? quote?.quotesRequest?.type + ' Insurance' : QUOTES_CATEGORY_NAME_MAP[quote.quotesRequest.metaData.quoteCategory],
                    'offerDateTime': new Date(quote.metaData.offerDate + 'Z'),
                    'industry': quote.quotesRequest.metaData.company?.industry,
                    'address': quote.quotesRequest.metaData.company?.address,
                    'location': quote.quotesRequest.metaData.company?.city + ', ' + quote.quotesRequest.metaData.company?.state,
                    'year_in_business': new Date().getFullYear() - parseInt(quote.quotesRequest.metaData.company?.establishedYear),
                    'annual_revenue': quote.quotesRequest.metaData.company?.annualRevenue,
                    'employer_count': quote.quotesRequest.metaData.company?.employeeCounts,
                    'time_to_expire': null,
                    'company_info': quote.quotesRequest.metaData.company,
                    'download_link': quote.quotesRequest.metaData.redactedDocDownloadLink,
                    'domain_data': fillDomainData(quote, false)
                }
          }) 

          tableData.sort((a, b) => b.offerDateTime - a.offerDateTime);
      } else {
          tableData = bidsDataArr.map(quote => {
              toggleHideColumn("time_to_expire", true);
                return {
                'request_id': quote?.quotesRequestMetaData?.id,
                'request': QUOTES_CATEGORY_NAME_MAP[quote.quotesRequest.metaData.quoteCategory],
                'request_display_name': QUOTES_CATEGORY_NAME_MAP[quote?.quotesRequestMetaData.quoteCategory],
                'requestDateTime': new Date(quote?.quotesRequestMetaData?.requestTime + 'Z'),
                'industry': quote?.quotesRequestMetaData?.company?.industry,
                'address': quote?.quotesRequestMetaData?.company?.address,
                'location': quote?.quotesRequestMetaData?.company?.city + ', ' + quote?.quotesRequestMetaData?.company?.state,
                'year_in_business': new Date().getFullYear() - parseInt(quote?.quotesRequestMetaData?.company?.establishedYear),
                'annual_revenue': quote?.quotesRequestMetaData?.company?.annualRevenue,
                'employer_count': quote?.quotesRequestMetaData?.company?.employeeCounts,
                'time_to_expire': null,
                'company_info': quote?.quotesRequestMetaData?.company,
                'download_link':quote?.quotesRequestMetaData?.redactedDocDownloadLink,
                'domain_data': null,
            }
          })
      }

        setData(tableData)
    }

    const calcTimeToExpire = (time) => {

        const localExpireDate = convertUTCDateToLocalDate(new Date(time));

        let milliseconds = new Date(localExpireDate) - new Date();

        if (milliseconds < 0) {
            return '00:00:00 - expired';
        }

        let seconds = Math.floor(milliseconds/1000);

        let minutes = Math.floor(seconds/60);
      
        let hours = Math.floor(minutes/60);
      
        seconds = seconds % 60;
      
        minutes = minutes % 60;
      
        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    }

    const convertUTCDateToLocalDate = (date) => {
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
    
        newDate.setHours(hours - offset);
    
        return newDate;   
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const columns = useMemo(() => openBidTableColumns, []);

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,        
        rows,        
        prepareRow,
        toggleHideColumn,
    } = useTable({
        columns,
        data
    })

    // Used for handle click outside the modal and close it
    const { ref } = useModalVisible(setShowModal);

    const handleClickViewBtn = (i) => {
        setViewBtnRowIdx(i);
        setShowModal(!showModal);
    }

    const handleClickTableTab = (i) => {
        setTabIdx(i);
    }

    return (
      <section className="provider-content-ctnr">
        <div className="open-bids-tab-row">
          <div className="open-bids-tab-item" onClick={() => { handleClickTableTab(0) }}>
            <div className={ tabIdx === 0 ? "open-bids-tab-title-highlight" : "open-bids-tab-title"}>
              <div className="bids-title"> Open Bids </div>
            </div>
            <div className={ tabIdx === 0 ? "open-bids-tab-value-highlight" : "open-bids-tab-value"}>
              <div className="bids-value">
                {request ? (
                  <>{openBids.length > 0 ? openBids.length : 0}</>
                ) : null}
              </div>
            </div>
          </div>

          <div className="open-bids-tab-item" onClick={() => { handleClickTableTab(1); }}>
            <div className={ tabIdx === 1 ? "open-bids-tab-title-highlight" : "open-bids-tab-title"}>
              <div className="bids-title"> Pending </div>
            </div>
            <div className={ tabIdx === 1 ? "open-bids-tab-value-highlight" : "open-bids-tab-value"}>
              <div className="bids-value">
                {request ? (
                  <>{pendingBids.length > 0 ? pendingBids.length : 0}</>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="open-bids-tab-item" onClick={() => { handleClickTableTab(2); }}>
            <div className={ tabIdx === 2 ? "open-bids-tab-title-highlight" : "open-bids-tab-title"}>
              <div className="bids-title"> Hidden </div>
            </div>
            <div className={ tabIdx === 2 ? "open-bids-tab-value-highlight" : "open-bids-tab-value"}>
              <div className="bids-value">
                {request ? (
                  <>{hiddenBids.length > 0 ? hiddenBids.length : 0}</>
                ) : null}
              </div>
            </div>
          </div> */}
        </div>

        <div className="bids-table-ctnr">
          <table className="bids-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr className="bids-table-tr" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                    <th className="bids-table-th" {...column.getHeaderProps()}>
                      {" "}
                      {column.render("Header")}                      
                    </th>
                  )}
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps} style={{overflow: "scroll"}}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td className="bids-table-td" {...cell.getCellProps()}>
                          {cell.render("Cell", {handleClickViewBtn, tabIdx})}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div ref={ref}>
          {showModal &&
            renderQuotesRequestModal(
              data[viewBtnRowIdx],
              tabIdx,
              refresh,
              setShowModal,
              false
            )}
        </div>
      </section>
    );
};

export default OpenBidPage;