import React, {useEffect, useState, useMemo, useRef} from "react";
import { useTable } from 'react-table';
import { useNavigate, useParams } from 'react-router-dom';
import QuotesService from "../../../../services/QuotesService";

import {FaCheckCircle} from 'react-icons/fa';

import {formatNumberInEnUs} from "../../Util/MathUtil";

import '../../../../css/Provider/ProviderQuoteDetailPage.css';

const OfferAnalysisTableColumns = [  
    {
        Header: '',
        accessor: 'element_name',
        className: 'provider-quote-detail-deal-review-card-offer-analysis-element-name-col',
        headerClassName: 'provider-quote-detail-deal-review-card-offer-analysis-element-name-col'
    },
    {
        Header: 'Current',
        accessor: 'current_offer',
        className: 'provider-quote-detail-deal-review-card-offer-analysis-current-offer-col',
        headerClassName: 'provider-quote-detail-deal-review-card-offer-analysis-current-offer-col'
    },
    {
        Header: 'Your Offer',
        accessor: 'new_offer',
        className: 'provider-quote-detail-deal-review-card-offer-analysis-new-offer-col',
        headerClassName: 'provider-quote-detail-deal-review-card-offer-analysis-new-offer-col'
    }
]

const ProviderQuoteDetailPage = () => {

    let navigate = useNavigate();
    let { quoteId } = useParams();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const dropdownRef = useRef(null);
    const statuses = [
        { title: 'Outreached', subtitle: 'Review offer with Client' },
        { title: 'Deal Lost', subtitle: 'Client not moving forward' },
        { title: 'Deal Accepted', subtitle: 'Client accepted deal offline' }
    ];

    const handleStatusClick = (status) => {
        setSelectedStatus(status.title);
        setIsOpen(false);
    };

    const handleStatusSavebtnClick = () => {
        const request = { "quoteId": quoteId }
        let quoteStatusKey = '';

        if (selectedStatus === 'Outreached') {
            if (!!quoteDetail?.quoteDTO?.requestCallbackDate) {
                quoteStatusKey = 'CALLBACK_SET';
            } else {
                quoteStatusKey = 'REVEALED_IN_TOP_PLACEMENT';
            }
        } else if (selectedStatus === 'Deal Lost') {
            quoteStatusKey = 'LOST';
        } else if (selectedStatus === 'Deal Accepted') {
            quoteStatusKey = 'ACCEPTED';
        }

        request.quoteStatus = quoteStatusKey;

        QuotesService.providerUpdateQuoteStatus(request)
            .then(res => {
                alert('Status updated!');
            })
            .catch(e => {
                alert('Something wrong. Failed to update the status :(');
                console.log(e);
            })
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const [quoteDetail, setQuoteDetail] = useState({});
    const [data, setData] = useState([]);

    const columns = useMemo(() => OfferAnalysisTableColumns, []);

    // setting data to table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })
    
    const refresh = () => {
        QuotesService.getProviderQuoteDetail(quoteId)
            .then(res => {
                setQuoteDetail(res?.data);

                if (res?.data?.quoteStatus === 'REVEALED_IN_TOP_PLACEMENT' || res?.data?.quoteStatus === 'CALLBACK_SET') {
                    setSelectedStatus('Outreached');
                } else if (res?.data?.quoteStatus === 'ACCEPTED') {
                    setSelectedStatus('Deal Accepted');
                } else if (res?.data?.quoteStatus === 'LOST') {
                    setSelectedStatus('Deal Lost');
                }

                if (res?.data?.quotesCategory === 'PAYMENT_PROCESSING') {
                    const quoteDTO = res?.data?.paymentProcessingQuoteDTO;
                    setQuoteDetail(prevQuoteDetail => ({
                        ...prevQuoteDetail,
                        quoteDTO: quoteDTO
                    }))
                    
                    // set Offer Analysis table rows for Payment Processing
                    setData([
                        {
                            'element_name': 'Monthly Fee',
                            'current_offer': '$' + formatNumberInEnUs(quoteDTO?.curMonthlyFee),
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.newMonthlyFee)
                        },
                        {
                            'element_name': 'Effective Rate',
                            'current_offer': formatNumberInEnUs(quoteDTO?.curEffectiveRate * 100) + '%',
                            'new_offer': formatNumberInEnUs(quoteDTO?.newEffectiveRate * 100) + '%'
                        },       
                        {
                            'element_name': 'Annual Savings($)',
                            'current_offer': '',
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.newMonthlyFee)
                        },
                        {
                            'element_name': 'Annual Savings(%)',
                            'current_offer': '',
                            'new_offer': formatNumberInEnUs(quoteDTO?.savingPercentage * 100) + '%'
                        },    
                    ])
                } else if (res?.data?.quotesCategory === 'PAYROLL') {
                    const quoteDTO = res?.data?.payrollQuoteDTO;
                    setQuoteDetail(prevQuoteDetail => ({
                        ...prevQuoteDetail,
                        quoteDTO: quoteDTO
                    }))

                    // set Offer Analysis table rows for Payroll
                    setData([
                        {
                            'element_name': 'Payroll Fee',
                            'current_offer': '$' + formatNumberInEnUs(quoteDTO?.currentFee),
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.newFee)
                        },  
                        {
                            'element_name': 'Annual Savings($)',
                            'current_offer': '',
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.potentialAnnualSaving) 
                        },
                        {
                            'element_name': 'Annual Savings(%)',
                            'current_offer': '',
                            'new_offer': formatNumberInEnUs(quoteDTO?.savingPercentage * 100) + '%'
                        },    
                    ])  
                } else if (res?.data?.quotesCategory === 'PEO') {
                    const quoteDTO = res?.data?.peoQuoteDTO;
                    setQuoteDetail(prevQuoteDetail => ({
                        ...prevQuoteDetail,
                        quoteDTO: quoteDTO
                    }))

                    // set Offer Analysis table rows for Payroll
                    setData([
                        {
                            'element_name': 'Payroll Fee',
                            'current_offer': '$' + formatNumberInEnUs(quoteDTO?.curFee),
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.newFee)
                        },  
                        {
                            'element_name': 'Annual Savings($)',
                            'current_offer': '',
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.potentialAnnualSaving) 
                        },
                        {
                            'element_name': 'Annual Savings(%)',
                            'current_offer': '',
                            'new_offer': formatNumberInEnUs(quoteDTO?.savingPercentage * 100) + '%'
                        },    
                    ])  
                } else if (res?.data?.quotesCategory === 'BENEFITS') {
                    const quoteDTO = res?.data?.benefitsQuoteDTO;
                    setQuoteDetail(prevQuoteDetail => ({
                        ...prevQuoteDetail,
                        quoteDTO: quoteDTO
                    }))

                    // set Offer Analysis table rows for Retirement plan
                    setData([
                        {
                            'element_name': 'Benefits Fee',
                            'current_offer': '$' + formatNumberInEnUs(quoteDTO?.curFee),
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.newFee)
                        },  
                        {
                            'element_name': 'Annual Savings($)',
                            'current_offer': '',
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.potentialAnnualSaving) 
                        },
                        {
                            'element_name': 'Annual Savings(%)',
                            'current_offer': '',
                            'new_offer': formatNumberInEnUs(quoteDTO?.savingPercentage * 100) + '%'
                        },    
                    ])  
                } else if (res?.data?.quotesCategory === 'RETIREMENT_PLAN') {
                    const quoteDTO = res?.data?.retirementPlanQuoteDTO;
                    setQuoteDetail(prevQuoteDetail => ({
                        ...prevQuoteDetail,
                        quoteDTO: quoteDTO
                    }))

                    // set Offer Analysis table rows for Retirement plan
                    setData([
                        {
                            'element_name': 'Management Fee',
                            'current_offer': '$' + formatNumberInEnUs(quoteDTO?.curManagementFee),
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.newManagementFee)
                        },  
                        {
                            'element_name': 'Annual Savings($)',
                            'current_offer': '',
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.potentialAnnualSaving) 
                        },
                        {
                            'element_name': 'Annual Savings(%)',
                            'current_offer': '',
                            'new_offer': formatNumberInEnUs(quoteDTO?.savingPercentage * 100) + '%'
                        },    
                    ])  
                } else if (res?.data?.quotesCategory === 'INSURANCE') {
                    const quoteDTO = res?.data?.insuranceQuoteDTO;
                    setQuoteDetail(prevQuoteDetail => ({
                        ...prevQuoteDetail,
                        quoteDTO: quoteDTO
                    }))

                    // set Offer Analysis table rows for Payment Processing
                    setData([
                        {
                            'element_name': 'Annual Premium',
                            'current_offer': '$' + formatNumberInEnUs(quoteDTO?.currentPremium),
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.newPremium)
                        },  
                        {
                            'element_name': 'Annual Savings($)',
                            'current_offer': '',
                            'new_offer': '$' + formatNumberInEnUs(quoteDTO?.potentialAnnualSaving) 
                        },
                        {
                            'element_name': 'Annual Savings(%)',
                            'current_offer': '',
                            'new_offer': formatNumberInEnUs(quoteDTO?.savingPercentage * 100) + '%'
                        },    
                    ])                    
                }
            }).catch(e => console.log(e))
    }

    // on refresh get requets info from api
    useEffect(refresh, []);

    const [activeTab, setActiveTab] = useState('provider-quote-detail-request-card-client-summary');


    const renderClientSummaryTab = () => {
        return (
            <div>
                <div className='provider-quote-detail-request-card-body-ctnr'>
                    <div className='provider-quote-detail-request-card-body-ctnr-title'>
                        Customer Details
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Name </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.userFirstName + ' ' + quoteDetail?.userLastName } </div>
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Role </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.userJobTitle } </div>
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Email </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.userEmail } </div>
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Phone </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.userPhone } </div>
                    </div>                        
                </div>

                <div className='provider-quote-detail-request-card-body-ctnr'>
                    <div className='provider-quote-detail-request-card-body-ctnr-title'>
                        Business Details
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Business </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.requesterCompany?.name }  </div>
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Address </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.requesterCompany?.address + ', ' + quoteDetail?.requesterCompany?.city + ', ' + quoteDetail?.requesterCompany?.state + ' '+ quoteDetail?.requesterCompany?.zipCode } </div>
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Industry </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.requesterCompany?.industry } </div>
                    </div>
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Year in Business (YIB) </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { new Date().getFullYear() - Number(quoteDetail?.requesterCompany?.establishedYear) } year(s) </div>
                    </div>                        
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Annual Revenue </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.requesterCompany?.annualRevenue } </div>
                    </div>     
                    <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Employees </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.requesterCompany?.employeeCounts } </div>
                    </div>     
                </div>                
            </div>
        )
    }

    const renderDealSummaryTab = () => {
        if (quoteDetail?.quotesCategory === 'PAYMENT_PROCESSING') {
            return (
                <div>
                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            Current Rates
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Transactions (monthly) </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { formatNumberInEnUs(quoteDetail?.quoteDTO?.monthlyTransaction) } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Volume (monthly) </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.monthlyTransaction) } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Fees (monthly) </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.curMonthlyFee) } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Effective Rate </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { formatNumberInEnUs(quoteDetail?.quoteDTO?.curEffectiveRate * 100) } % </div>
                        </div>                        
                    </div> 
    
                    <div className="provider-quote-detail-request-card-your-offer-ctnr">
                        <div className="provider-quote-detail-request-card-your-offer-title">
                            You Offer:
                        </div>
                        <div className="provider-quote-detail-request-card-offer-rate-ctnr">
                            <div className="provider-quote-detail-request-card-offer-rate-title"> Offer Rate</div>
                            <div className="provider-quote-detail-request-card-offer-rate-value"> { (quoteDetail?.quoteDTO?.newEffectiveRate * 100).toFixed(2) } % </div>
                        </div>
                    </div>
                </div>
            )
        } else if (quoteDetail?.quotesCategory === 'PAYROLL') {
            return (
                <div>
                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            Current Terms
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Employees </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.currentEmployees } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Payment Frequency </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.paymentFrequency } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Fee </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.currentFee) } </div>
                        </div>                 
                    </div> 
    
                    <div className="provider-quote-detail-request-card-your-offer-ctnr">
                        <div className="provider-quote-detail-request-card-your-offer-title">
                            You Offer:
                        </div>
                        <div className="provider-quote-detail-request-card-offer-rate-ctnr">
                            <div className="provider-quote-detail-request-card-offer-rate-title"> Offer Fee</div>
                            <div className="provider-quote-detail-request-card-offer-rate-value"> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.newFee) } </div>
                        </div>
                    </div>
                </div>
            )            
        } else if (quoteDetail?.quotesCategory === 'PEO') {
            return (
                <div>
                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            General term
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Employees </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.currentEmployees } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Payroll Frequency </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.paymentFrequency } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Renewal Date </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.renewalDate } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Fee </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.curFee) } </div>
                        </div>                 
                    </div> 

                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            Benefits
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Name of Group </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.nameOfGroup } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Type of Benefits </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.benefitsType } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Enrolled Employees </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.numOfEnrolledEmployees } </div>
                        </div>             
                    </div> 

                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            Retirement Plan
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Amount In Plan </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ quoteDetail?.quoteDTO?.amountInPlan } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Safe Harbor </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.safeHarbor } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Annual Flow </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.annualFlow) } </div>
                        </div>      
                    </div> 
    
                    <div className="provider-quote-detail-request-card-your-offer-ctnr">
                        <div className="provider-quote-detail-request-card-your-offer-title">
                            You Offer:
                        </div>
                        <div className="provider-quote-detail-request-card-offer-rate-ctnr">
                            <div className="provider-quote-detail-request-card-offer-rate-title"> Offer Fee</div>
                            <div className="provider-quote-detail-request-card-offer-rate-value"> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.newFee) } </div>
                        </div>
                    </div>
                </div>
            )            
        } else if (quoteDetail?.quotesCategory === 'BENEFITS') {
            return (
                <div>
                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            Current Terms
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Name of Group </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.nameOfGroup } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Type of Benefits </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.benefitsType } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Enrolled Employees </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.numOfEnrolledEmployees } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Majority of Employee Location </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.majorityOfEmployeeLocation } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Entity Domicile </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.entityDomicile } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Renewal Date </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.renewDate } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Fee </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.curFee) } </div>
                        </div>                 
                    </div> 
    
                    <div className="provider-quote-detail-request-card-your-offer-ctnr">
                        <div className="provider-quote-detail-request-card-your-offer-title">
                            You Offer:
                        </div>
                        <div className="provider-quote-detail-request-card-offer-rate-ctnr">
                            <div className="provider-quote-detail-request-card-offer-rate-title"> Offer Fee</div>
                            <div className="provider-quote-detail-request-card-offer-rate-value"> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.newFee) } </div>
                        </div>
                    </div>
                </div>
            )            
        } else if (quoteDetail?.quotesCategory === 'RETIREMENT_PLAN') {
            return (
                <div>
                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            Current Terms
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Amount In Plan </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.amountInPlan } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Safe Harbor </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.safeHarbor } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Annual Flow </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.annualFlow) } </div>
                        </div>  
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Current Management Fee </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.curManagementFee) } </div>
                        </div>                 
                    </div> 
    
                    <div className="provider-quote-detail-request-card-your-offer-ctnr">
                        <div className="provider-quote-detail-request-card-your-offer-title">
                            You Offer:
                        </div>
                        <div className="provider-quote-detail-request-card-offer-rate-ctnr">
                            <div className="provider-quote-detail-request-card-offer-rate-title"> New Management Fee</div>
                            <div className="provider-quote-detail-request-card-offer-rate-value"> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.newManagementFee) } </div>
                        </div>
                    </div>
                </div>
            )            
        } else if (quoteDetail?.quotesCategory === 'INSURANCE') {
            return (
                <div>
                    <div className='provider-quote-detail-request-card-body-ctnr'>
                        <div className='provider-quote-detail-request-card-body-ctnr-title'>
                            Current Terms
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Insurance Type </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.type } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Coverage Amount </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'>  { quoteDetail?.quoteDTO?.coverage } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Premium </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.currentPremium) } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Entity Type </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.entityType } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Primary Operation </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.primaryOperation } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Other Business Coverages </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.otherBusinessCoverage } </div>
                        </div>
                        <div className='provider-quote-detail-request-card-body-ctnr-row-ctnr'>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-title'> Previous Claim Description (last 5 years) </div>
                            <div className='provider-quote-detail-request-card-body-ctnr-row-value'> { quoteDetail?.quoteDTO?.prevClaimDesc } </div>
                        </div>                 
                    </div> 
    
                    <div className="provider-quote-detail-request-card-your-offer-ctnr">
                        <div className="provider-quote-detail-request-card-your-offer-title">
                            You Offer:
                        </div>
                        <div className="provider-quote-detail-request-card-offer-rate-ctnr">
                            <div className="provider-quote-detail-request-card-offer-rate-title"> New premium</div>
                            <div className="provider-quote-detail-request-card-offer-rate-value"> ${ formatNumberInEnUs(quoteDetail?.quoteDTO?.newPremium) } </div>
                        </div>
                    </div>
                </div>
            )      
        }
    }

    const getStatusColorClass = (status) => {
        switch (status) {
            case 'Outreached':
                return 'provider-quote-detail-options-color-outreached';
            case 'Deal Lost':
                return 'provider-quote-detail-options-color-deal-lost';
            case 'Deal Accepted':
                return 'provider-quote-detail-options-color-deal-accepted';
            default:
                return '';
        }
    }

    return (
        <section className="provider-quote-detail-section">
            <div className="provider-quote-detail-top-row">
                <div>
                    <div className="provider-quote-detail-top-row-name"> {quoteDetail?.userFirstName + ' ' + quoteDetail?.userLastName} </div>
                    <div className="provider-quote-detail-top-row-subtitle"> 
                        Quote ID: { quoteDetail?.quoteDTO?.quoteId + ' - ' }
                        Placed on { new Date(quoteDetail?.requestDateTime + 'Z').toLocaleString() }
                    </div>
                </div>
                <div className="provider-quote-detail-top-row-right-end" ref={dropdownRef}> 
                    <div 
                        className="provider-quote-detail-top-row-status-dropdown"
                        onClick={() => setIsOpen(!isOpen)}
                    > 
                        Status: <span className={`${getStatusColorClass(selectedStatus)}`}> {selectedStatus} </span> <span className="triangle">&#9660;</span>
                        {isOpen && (
                            <div className="provider-quote-detail-options-dropdown-content">
                                {statuses.map(status => (
                                    <div 
                                        key={status.title} 
                                        className="provider-quote-detail-options-dropdown-content-dropdown-item"
                                        onClick={() => handleStatusClick(status)}
                                    >
                                        <div className={`provider-quote-detail-options-dropdown-content-dropdown-title ${getStatusColorClass(status.title)}` }>{status.title}</div>
                                        <div className="provider-quote-detail-options-dropdown-content-dropdown-subtitle">{status.subtitle}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <button 
                        className="dropdown-save-btn"
                        onClick={() => handleStatusSavebtnClick()}
                    >
                        Done
                    </button>
                </div>
            </div>

            <div className="provider-quote-detail-main-section">
                <div>
                    <div className="provider-quote-detail-deal-review-card">
                        <div className="provider-quote-detail-deal-review-card-deal-review"> Deal Review </div>
                        <div className="provider-quote-detail-deal-review-card-deal-review-subtitle"> Directly reach out to the client to further review your offer and close the deal</div>

                        <div className="provider-quote-detail-deal-review-card-callback-request"> Callback Request </div>
                        <div className="provider-quote-detail-deal-review-card-callback-range-ctnr">
                            <div className="provider-quote-detail-deal-review-card-callback-range">
                                <div> 
                                    <FaCheckCircle size="20px" style={{"marginRight": "10px", "marginBottom": "2px"}}/>
                                    { quoteDetail?.quoteDTO?.requestCallbackDate ?  quoteDetail?.quoteDTO?.requestCallbackDate : 'Callback not requested from client yet' }
                                </div>
                                <div> { quoteDetail?.quoteDTO?.requestCallbackDate ? quoteDetail?.quoteDTO?.requestCallbackTimeRange : '' } </div>
                            </div>
                        </div>

                        <div className="provider-quote-detail-deal-review-card-offer-analysis-ctnr">
                            <div className="provider-quote-detail-deal-review-card-offer-analysis-title"> Offer Analysis </div>
                            <div className="provider-quote-detail-deal-review-card-offer-analysis-table">
                                <table {...getTableProps()}>
                                    <thead>
                                    {
                                        headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps()} className={column.headerClassName}> {column.render('Header')}</th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                    </thead>
                                    <tbody {...getTableBodyProps} style={{overflow: 'scroll'}}>
                                    {
                                        rows.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {
                                                        row.cells.map(cell =>  {
                                                            return <td {...cell.getCellProps()} className={cell.column.className}>{cell.render('Cell', {})}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>                
                            </div>                        
                        </div>
                    </div>

                    <div className="provider-quote-detail-footer-text"> If the client does not wish to move forward, Change the status to "Deal Lost" </div>
                </div>

                <div className="provider-quote-detail-request-card">
                    <div className="provider-quote-detail-request-card-top-row">
                        <div className="provider-quote-detail-request-card-top-row-business-name">
                            { quoteDetail?.requesterCompany?.name }
                        </div>
                        <div className="provider-quote-detail-request-card-top-row-request-id">
                            Request ID: { quoteDetail?.quoteDTO?.requestId }
                        </div>
                    </div>
                    <div className="provider-quote-detail-request-card-top-row-view-docs">
                        <a href={ quoteDetail?.redactedDocDownloadLink } target="_blank" rel="noopener noreferrer">
                            <u>View Documents</u>
                        </a>
                    </div>
                    
                    <div className="provider-quote-detail-request-card-tab-row">
                        <div 
                            className={`provider-quote-detail-request-card-tab ${activeTab === 'provider-quote-detail-request-card-client-summary' ? 'active' : ''}`}
                            onClick={() => setActiveTab('provider-quote-detail-request-card-client-summary')}
                        >
                            Client Summary
                        </div>
                        <div 
                            className={`provider-quote-detail-request-card-tab ${activeTab === 'provider-quote-detail-request-card-deal-summary' ? 'active' : ''}`}
                            onClick={() => setActiveTab('provider-quote-detail-request-card-deal-summary')}
                        > 
                            Deal Summary
                        </div>
                    </div>

                    { activeTab === 'provider-quote-detail-request-card-client-summary' ? renderClientSummaryTab() : renderDealSummaryTab() }
                </div>
            </div>
        </section>
    )
}

export default ProviderQuoteDetailPage;