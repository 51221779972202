import {Link} from "react-router-dom";
import HomeIcon from "../../../images/icon-sidenav-home.png"
import StarIcon from "../../../images/icon-sidenav-star.png";
import PhoneIcon from "../../../images/icon-sidenav-phone.png";
import BarChartIcon from "../../../images/icon-sidenav-bar-chart.png";
import SettingsIcon from "../../../images/icon-sidenav-settings.png";
import WhiteHomeIcon from "../../../images/home.png";
import WhiteWonIcon from "../../../images/star.png";
import WhiteSettingsIcon from "../../../images/icon-feather-settings.svg";
import { useEffect, useState } from "react";

import '../../../css/Provider/SidebarNav.css';

export default function SidebarNavigation() {

    const [page, setPage] = useState({home: false, won: false, profile: false});

    useEffect(()=>{
        if(window.location.pathname === '/provider'){
            setPage({home: true, won: false, profile: false});
        }
        if(window.location.pathname === '/provider/won-bid'){
            setPage({home: false, won: true, profile: false});
        }
        if (window.location.pathname === "/provider/bus-profile") {
          setPage({home: false, won: false, profile: true});
        }

  },[]);

  const handlePageChange = (page) => {
    setPage({
        home: page === 'home' ? true : false, 
        won: page === 'won' ? true : false,
        profile: page === 'profile' ? true: false,
    });
  }

    return (
      <nav className="sidebar-nav-ctnr">
        <Link to="/provider">
          {page.home ? (
            <div
              style={{backgroundColor: "#856BD9"}}
              className="nav-icon-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("home")}
            >
              <img
                className="nav-icon"
                src={WhiteHomeIcon}
                alt="white home icon"
              />
            </div>
          ) : (
            <div
              className="nav-icon-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("home")}
            >
              <img className="nav-icon" src={HomeIcon} alt="home icon" />
            </div>
          )}
        </Link>
        {/* <Link to="/provider/top-deal-placement">
          {page.won ? (
            <div
              style={{backgroundColor: "#856BD9"}}
              className="nav-icon-ctnr"
              tabIndex={0}
              onClick={() => handlePageChange("won")}
            >
              <img className="nav-icon" src={WhiteWonIcon} alt="won icon" />
            </div>
          ) : (
            <div
              className="nav-icon-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("won")}
            >
              <img className="nav-icon" src={StarIcon} alt="star icon" />
            </div>
          )}
        </Link> */}
        <Link to="/provider/callbacks">
          <div className="nav-icon-ctnr" tabIndex={0} style={{display: "none"}}>
            <img className="nav-icon" src={PhoneIcon} alt="phone icon" />
          </div>
        </Link>
        <Link to="/provider/won-bid">
          <div className="nav-icon-ctnr" tabIndex={0} style={{display: "none"}}>
            <img className="nav-icon" src={BarChartIcon} alt="bar chart icon" />
          </div>
        </Link>
        <Link to="/provider/bus-profile">
          {page.profile ? (
            <div
              style={{backgroundColor: "#856BD9"}}
              className="nav-icon-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("profile")}
            >
              <img
                className="nav-icon"
                src={WhiteSettingsIcon}
                alt="settings icon"
              />
            </div>
          ) : (
            <div
              className="nav-icon-ctnr"
              tabIndex={0}
              id="myInput"
              onClick={() => handlePageChange("profile")}
            >
              <img className="nav-icon" src={SettingsIcon} alt="settings icon" />
            </div>
          )}
        </Link>
      </nav>
    );
}