import PaymentProcessingQuotesRequestModal from "../Provider/Modal/PaymentProcessingQuotesRequestModal";
import PayrollQuotesRequestModal from "../Provider/Modal/PayrollQuotesRequestModal";
import React from "react";
import LoanAndCreditRequestModal from "../Provider/Modal/LoanAndCreditQuotesRequestModal";
import InsuranceQuotesRequestModal from "../Provider/Modal/InsuranceQuotesRequestModal";
import RetirementPlanQuotesRequestModal from "../Provider/Modal/RetirementPlanQuotesRequestModal";
import BenefitsQuotesRequestModal from "../Provider/Modal/BenefitsQuotesRequestModal";
import PeoQuotesRequestModal from "../Provider/Modal/PeoQuotesRequestModal";

export const fillDomainData = (data, isOpenBid) => {
    let quotesRequest;
    quotesRequest = isOpenBid ? data : data.quotesRequest;

    if (quotesRequest?.metaData?.quoteCategory === 'PAYMENT_PROCESSING') {
        return {
            'monthly_transaction': quotesRequest?.monthlyTransaction,
            'monthly_volume': quotesRequest?.monthlyVolume,
            'monthly_fee': quotesRequest?.monthlyFee,
            'pos_contact': quotesRequest?.posContact,
            'effective_rate': quotesRequest?.effectiveRate,
            'offer_rate': !isOpenBid ? data?.newEffectiveRate : undefined
        }
    } else if (quotesRequest?.metaData?.quoteCategory === 'PEO') {
    
        var peoObj = isOpenBid ? JSON.parse(data.peoData) : JSON.parse(quotesRequest.peoData);

        return {
            'current_employees': quotesRequest?.currentEmployees,
            'payment_frequency': quotesRequest?.paymentFrequency,
            'renewal_date': peoObj?.renewalDate,
            'new_fee_monthly': peoObj?.totalFeeMonthly,

            'is_benefits_inclued': peoObj?.isBenefitsSelected,
            'name_of_group': quotesRequest?.benefitsData?.nameOfGroup,
            'type_of_benefits': quotesRequest?.benefitsData?.benefitsType,
            'num_of_employee_enrolled': quotesRequest?.benefitsData?.numOfEnrolledEmployees,

            'is_retirement_plan_included': peoObj?.isRetirementPlanSelected,
            'amount_in_plan': quotesRequest?.retirementPlanData?.amountInPlan,
            'safe_harbor': quotesRequest?.retirementPlanData?.safeHarbor,
            'annual_flow': quotesRequest?.retirementPlanData?.annualFlow
        }
    } else if (quotesRequest?.metaData?.quoteCategory === 'PAYROLL') {
        return {
            'current_employees': quotesRequest?.currentEmployees,
            'payment_frequency': quotesRequest?.paymentFrequency,
            'current_fee': quotesRequest?.currentFee,
            'new_fee': !isOpenBid ? data?.newFee : undefined
        }
    } else if (quotesRequest?.metaData?.quoteCategory === 'BENEFITS') {
        return {
            'nameOfGroup': quotesRequest?.benefitsData?.nameOfGroup,
            'benefitsType': quotesRequest?.benefitsData?.benefitsType,
            'numOfEnrolledEmployees': quotesRequest?.benefitsData?.numOfEnrolledEmployees,
            'majorityOfEmployeeLocation': quotesRequest?.benefitsData?.majorityOfEmployeeLocation,
            'entityDomicile': quotesRequest?.benefitsData?.entityDomicile,
            'renewDate': quotesRequest?.benefitsData?.renewDate,
            'curFee': quotesRequest?.benefitsData?.curFee,
            'newBenefitsFee': !isOpenBid ? data?.newBenefitsFee : undefined
        }
    } else if (quotesRequest?.metaData?.quoteCategory === 'RETIREMENT_PLAN') {
        return {
            'amount_in_plan': quotesRequest?.retirementPlanData?.amountInPlan,
            'safe_harbor': quotesRequest?.retirementPlanData?.safeHarbor,
            'annual_flow': quotesRequest?.retirementPlanData?.annualFlow,
            'cur_management_fee': quotesRequest?.retirementPlanData?.curManagementFee,
            'new_management_fee': !isOpenBid ? data?.newRetirePlanManagementFee : undefined
        }
    } else if (quotesRequest?.metaData?.quoteCategory === 'CREDIT_LINE' || quotesRequest?.metaData?.quoteCategory === 'LOAN') {
        return {
            'amount': quotesRequest?.amount,
            'assets': quotesRequest?.assets,
            'auto_debit': quotesRequest?.autoDebit,
            'current_rate': quotesRequest?.currentRate,
            'depreciation': quotesRequest?.depreciation ,
            'est_credit_score': quotesRequest?.estCreditScore,
            'gross_sales': quotesRequest?.grossSales,
            'interest_expenses': quotesRequest?.interestExpenses,
            'liabilities': quotesRequest?.liabilities,
            'maturity': quotesRequest?.maturity,
            'net_income': quotesRequest?.netIncome,
            'purpose': quotesRequest?.purpose,
            'term': quotesRequest?.term,
            'type': quotesRequest?.type,
            'new_rate': !isOpenBid ? data?.newRate : undefined
        }
    } else if (quotesRequest?.metaData?.quoteCategory === 'INSURANCE') {
        return {
            'coverage_amount': quotesRequest?.coverageAmount,
            'entity_type': quotesRequest?.entityType ,
            'est_credit_score': quotesRequest?.estCreditScore,
            'premium': quotesRequest?.premium,
            'other_business_coverage': quotesRequest?.otherBusinessCoverage,
            'primary_operation': quotesRequest?.primaryOperation,
            'type': quotesRequest?.type,
            'prev_claim_desc': quotesRequest?.prevClaimDesc,
            'new_premium': !isOpenBid ? data?.newPremium : undefined,
            'new_coverage': !isOpenBid ? data?.newCoverage: undefined
        }
    }
}

export const renderQuotesRequestModal = (data, tabIdx, refresh, setShowModal, isFromWonBidPage) => {

    switch (data?.request) {
        case 'Payment Processing':
            return (
                <PaymentProcessingQuotesRequestModal
                    rowData={data}
                    isOpenBidsModal={tabIdx === 0}
                    isWonBidModal={isFromWonBidPage}
                    handleClickModalSubmitBtn={() => {
                        refresh(1);
                        setShowModal(false);
                    }}
                    handleClickHideBidBtn={()=> {
                        refresh(2);
                        setShowModal(false)
                    }}
                />
            )

        case 'PEO': 
            return (
                <PeoQuotesRequestModal
                    rowData={data}
                    isOpenBidsModal={tabIdx === 0}
                    isWonBidModal={isFromWonBidPage}
                    handleClickModalSubmitBtn={() => {
                        refresh(1);
                        setShowModal(false);
                    }}
                    handleClickHideBidBtn={()=> {
                        refresh(2);
                        setShowModal(false)
                    }}
                />           
            )

        case 'Payroll':
            return (
                <PayrollQuotesRequestModal
                    rowData={data}
                    isOpenBidsModal={tabIdx === 0}
                    isWonBidModal={isFromWonBidPage}
                    handleClickModalSubmitBtn={() => {
                        refresh(1);
                        setShowModal(false);
                    }}
                    handleClickHideBidBtn={()=> {
                        refresh(2);
                        setShowModal(false)
                    }}
                />
            )
        
        case 'Benefits':
            return (
                <BenefitsQuotesRequestModal
                    rowData={data}
                    isOpenBidsModal={tabIdx === 0}
                    isWonBidModal={isFromWonBidPage}
                    handleClickModalSubmitBtn={() => {
                        refresh(1);
                        setShowModal(false);
                    }}
                    handleClickHideBidBtn={()=> {
                        refresh(2);
                        setShowModal(false)
                    }}
                />                
            )            

        case 'Retirement Plan':
            return (
                <RetirementPlanQuotesRequestModal
                    rowData={data}
                    isOpenBidsModal={tabIdx === 0}
                    isWonBidModal={isFromWonBidPage}
                    handleClickModalSubmitBtn={() => {
                        refresh(1);
                        setShowModal(false);
                    }}
                    handleClickHideBidBtn={()=> {
                        refresh(2);
                        setShowModal(false)
                    }}
                />                
            )

        case 'Credit Line':
        case 'Loan':
            return (
                <LoanAndCreditRequestModal
                    rowData={data}
                    isOpenBidsModal={tabIdx === 0}
                    isWonBidModal={isFromWonBidPage}
                    handleClickModalSubmitBtn={() => {
                        refresh(1);
                        setShowModal(false);
                    }}
                    handleClickHideBidBtn={()=> {
                        refresh(2);
                        setShowModal(false)
                    }}
                />
            )
        case 'Insurance':
            return (
                <InsuranceQuotesRequestModal
                    rowData={data}
                    isOpenBidsModal={tabIdx === 0}
                    isWonBidModal={isFromWonBidPage}
                    handleClickModalSubmitBtn={() => {
                        refresh(1);
                        setShowModal(false);
                    }}
                    handleClickHideBidBtn={()=> {
                        refresh(2);
                        setShowModal(false)
                    }}
                />
            )
    }
}