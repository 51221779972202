import React, { useEffect } from 'react';
import './Quotes.css';
import { 
    useState,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import CallbackModal from '../CallbackModal/CallbackModal';
import AboutProviderModal from '../AboutProviderModal/AboutProviderModal';
import DislikeQuoteModal from '../DislikeQuoteModal/DislikeQuoteModal';
import QuotesService from "../../../../services/QuotesService";
import {CellphoneIconSVG} from "../../../../images/svgComponents";
import {FaCheckCircle} from 'react-icons/fa';
import {formatNumberInEnUs} from "../../Util/MathUtil";
import { REVEALED_QUOTES_STATUS_SET } from "../../Constants";

const RetirementPlanQuotes = () => {

    let { id } = useParams(); 
    let location = useLocation();


    const [show, setShow] = useState({
        show: false,
        modal: '',
        quoteId: null,
        quote: null,
    });
    const [quotes, setQuotes] = useState([])
    const [displayedQuotes, setDisplayedQuotes] = useState([]);
    const [nonDisplayedQuotes, setNonDisplayedQuotes] = useState([]);
    const [refreshPage, setRefreshPage] = useState(false);

    useEffect(() => {
        QuotesService.getRetirementPlanOffersByRequestId(id).then(res => {
            const quotesData = res?.data?.map(quote => {
                return {
                    quoteId: quote.quoteId,
                    quoteStatus: quote?.quoteStatus,
                    provider: quote.provider,
                    curManagementFee: quote.curManagementFee,
                    newManagementFee: quote.newManagementFee,
                    potentialMonthlySavings: quote.potentialMonthlySavings,
                    potentialAnnualSavings: quote.potentialAnnualSaving,
                    requestCallbackDate: quote.requestCallbackDate,
                    requestCallbackTimeRange: quote.requestCallbackTimeRange
                }
            })

            quotesData.sort((a, b) => {
                return b.potentialMonthlySavings - a.potentialMonthlySavings
            });

            setQuotes(quotesData);
            setDisplayedQuotes(quotesData.filter(quote => REVEALED_QUOTES_STATUS_SET.has(quote?.quoteStatus)));
            setNonDisplayedQuotes(quotesData.filter(quote => !REVEALED_QUOTES_STATUS_SET.has(quote?.quoteStatus)));        
        }).catch(e => {
            console.log('failed to fetch quotes', e);
        })

        setRefreshPage(false);
    }, [refreshPage])

    const getModal = () => {
        const modalData = {
            quoteId: show.quoteId,
            quote: show.quote,
            quotesCategory: 'PAYMENT_PROCESSING'
        }

        switch (show.modal) {
            case 'callback':
                return <CallbackModal handleClose={(toRefreshPage) => hideCallbackModal(toRefreshPage)} data={modalData}/>;
            case 'about':
                return <AboutProviderModal handleClose={() => hideCallbackModal(false)} data={modalData}/>;
            case 'dislike':
                return <DislikeQuoteModal handleClose={() => hideCallbackModal(false)} data={modalData}/>;
            default:
                break;
        }
    };

    const showCallbackModal = (value, quoteId, quote) => {
        setShow({
            show: true,
            modal: value,
            quoteId: quoteId,
            quote: quote
        });
    };

    const hideCallbackModal = (toRefreshPage) => {
        if (toRefreshPage) {
            setRefreshPage(true);
        }

        setShow({
            show: false,
            modal: '',
            quoteId: null,
            quote: null
        });
    };

    const handleShowThisOfferBtnClick = () => {
        if (nonDisplayedQuotes.length > 0) {
            QuotesService.userShowAdditionalOffer({
                quoteRequestId: id,
                quoteId: nonDisplayedQuotes[0]?.quoteId
            }).then(res => {
                setRefreshPage(true);
                alert('Show one more offer!');
            }).catch(e => {
                alert('Something wrong! Failed to show one more offer!');
                console.log(e);
            })
        } else {
            alert('Sorry, we cannot find more offers!');
        }
    }

    return (
        <div>
            {show.show ? getModal() : null}
            <Header/>
            <div className='quotes-container'>
                <div className='content-ctnr'>
                    <h2 className='quote-category-header'> { location.state.quotesCategoryDisplayName } </h2>
                    <div className='quotes-scroll-container'>
                        {
                            displayedQuotes.map(quote =>
                                <div className='quote-card' key={quote.quoteId}>
                                    {
                                        quote.requestCallbackDate &&
                                        <div className='callback-requested-header-ctnr'>
                                            <div className='callback-requested-header-left-section'>
                                                <div className='callback-requested-header-icon'>
                                                    <CellphoneIconSVG/>
                                                </div>
                                                <div> Callback Requested </div>
                                            </div>
                                            <div className='callback-requested-header-right-section'>
                                                { quote.requestCallbackDate + ', ' + quote.requestCallbackTimeRange }
                                            </div>
                                        </div>
                                    }
                                    <div className='quote-header-ctnr'>
                                        <img className='quote-provider-logo' src={quote.provider.companyLogo} alt='Logo' />
                                        <div className='provider-name'>{quote.provider.businessName}</div>
                                        <div className='about-provider'>
                                            {quote.name}
                                            <div className='about-provider-link' onClick={() => showCallbackModal('about', quote.quoteId, quote)}>About this provider?</div>
                                            </div>
                                        <div className='dislike'>
                                            <div className='dislike-link' onClick={() => showCallbackModal('dislike', quote.quoteId, quote)}>Don't like this quote?</div>
                                        </div>
                                        <div className='callback-btn'>
                                            {quote.requestCallbackDate != null ?
                                                <FaCheckCircle size={42} style={{color: "#856BD9"}}/> :
                                                <button type='submit' className='req-callback-btn' onClick={() => showCallbackModal('callback', quote.quoteId, quote)}>Request Callback</button>
                                            }
                                        </div>
                                    </div>
                                    <div className='quote-body-ctnr'>
                                        <div className='current-terms'>
                                            <div className='item-header'>YOUR CURRENT TERMS</div>
                                            <div className='item-body'>
                                                <div className='item-title'> Current plan management fee </div>
                                                <div className='item-value'> ${ formatNumberInEnUs(quote.curManagementFee) } </div>
                                            </div>
                                        </div>
                                        <div className='offer-vs-ctnr'>
                                            <div className='offer-vs'>
                                                vs
                                            </div>
                                        </div>
                                        <div className='new-offer'>
                                            <div className='item-header'>NEW OFFER</div>
                                            <div className='item-body'>
                                                <div className='item-title'> New plan management fee </div>
                                                <div className='item-value'> ${ formatNumberInEnUs(quote.newManagementFee) } </div>
                                            </div>
                                        </div>
                                        <div className='potential-savings'>
                                            <div className='item-header'> POTENTIAL SAVINGS </div>
                                            <div className='item-sub-header'> To act on this offer, request a callback with this provider. </div>
                                            <div className='potential-savings-item'>
                                                <span style={{fontWeight: 'bold'}}> ${ formatNumberInEnUs(quote.potentialMonthlySavings) }</span>/month
                                            </div>
                                            <div className='potential-savings-item'>
                                                <span style={{fontWeight: 'bold'}}> ${ formatNumberInEnUs(quote.potentialAnnualSavings) }</span>/annually
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            quotes.length > displayedQuotes.length && (
                                <div className='quote-show-more-card'>
                                    <div className='quote-show-more-card-not-satisfied-txt'> Not 100% satisfied with the offer above or want to review more options? </div>
                                    <div className='quote-show-more-card-see-the-next-txt'> See the next best offer we found for you: </div>
                                    <div className='quote-show-more-card-select-to-show-txt'> By selecting to show this offer, this Provider will be able to contact you. </div>
                                    <button className='quote-show-more-card-show-this-offer-btn' onClick={() => handleShowThisOfferBtnClick()}>
                                        Show This Offer
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RetirementPlanQuotes;